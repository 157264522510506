import { mapState } from 'vuex';
import { dateUtilsMixin } from '../../../shared/mixins/dateUtilsMixin';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import {
  fetchAddresById,
  fetchExpensesByEmployee,
} from '../../helpers/employeeOptions';
import { canChange } from '../../../shared/helpers/permissionsUtils';
export default {
  components: { KnFormTitle },
  mixins: [dateUtilsMixin],
  props: {
    entity: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        { text: 'Fecha', value: 'fecha_elaboracion', class: 'purple--text' },
        { text: 'Concepto', value: 'categoria.dato', class: 'purple--text' },
        { text: 'Sub total', value: 'sub_total', class: 'purple--text' },
        { text: 'Impuestos', value: 'total_impuestos', class: 'purple--text' },
        { text: 'Descuentos', value: 'total_descuento', class: 'purple--text' },
        { text: 'Total', value: 'total_egreso', class: 'purple--text' },
        { text: '', value: 'acciones' },
      ],
      loading: false,
      fullAddress: null,
      items: [],
      expenses: [],
      employeeId: null,
      dateRange: {
        from: '',
        to: '',
      },
    };
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
  },
  async created() {
    this.employeeId = this.entity.id;
    await fetchAddresById(this.entity.datos_personales.direccion);
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    this.dateRange.from = this.getFirstDayOfMonth(currentYear, currentMonth);
    this.dateRange.to = this.getLastDayOfMonth(currentYear, currentMonth);
    const filterObj = {
      id_empleado: this.employeeId,
      fecha_inicio: this.dateRange.from,
      fecha_fin: this.dateRange.to,
      id_institucion_educativa: this.institutionId,
    };
    const expenseItems = await fetchExpensesByEmployee(filterObj);
    this.items = expenseItems;
    // console.log('expense items: ', expenseItems);
  },
  methods: {
    canChange: canChange,
    async filterByDateRange() {
      this.loading = true;
      const filterObj = {
        id_empleado: this.employeeId,
        fecha_inicio: this.dateRange.from,
        fecha_fin: this.dateRange.to,
        id_institucion_educativa: this.institutionId,
      };
      this.items = await fetchExpensesByEmployee(filterObj);
      this.loading = false;
    },
    goToEmployeeInfo() {
      this.$router.push({
        name: 'Editar Empleado',
        params: {
          entity: this.entity,
        },
      });
    },
  },
};
